const initialState = {
    filterCriteria : {},
    deviceList : [],
    size : 0,
    start : 0,
    end : 10,
    pageNumber : 1,
    totalSize:0,
    sortOrder : null,
    sortColumn : null,
    deviceTypes : [],
    deciveGroups : [],
    nextToken : null,
    deviceCount : 0,
    deviceDetails:[],
    error:null,
    campaignDetails:[]
}

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case 'FETCH_REPORT_SUCCESS' : {
            const campaignDetailArray = action.payload.data.data.data;
            const total =action.payload.data.data;
            // const devices = state.deviceList.concat(deviceDetailArray.devices);
            // console.log(devices)
            return { 
                ...state,
                // deviceList : devices, 
                campaignDetails : campaignDetailArray, 
                start : action.payload.start,
                end : action.payload.end,
                pageNumber : action.payload.pageNumber,
                totalSize: total.total_size
            }
        }
        case "FETCH_REPORT_REJECTED": {
            return {...state, error: action.payload}
        }
        case 'CLEAR_REPORT_MANAGEMENT' : {
            return {
                ...state,
                filterCriteria : {},
                deviceList : [],
                size : 0,
                start : 0,
                end : 10,
                pageNumber : 1,
                sortOrder : null,
                sortColumn : null,
                deviceTypes : [],
                deciveGroups : [],
                nextToken : null,
                deviceCount : 0,
                deviceDetails:[]
            }
        }
        case 'LOAD_NEXT_PAGE' : {
            return {
                ...state,
                start : action.payload.start,
                end : action.payload.end,
                pageNumber : action.payload.pageNumber,
                message : null
            }
        }
    }
    return state
}